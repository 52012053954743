import gql from "graphql-tag";

export const FETCH_ABOUT_CONTACT_CONTENT = gql`
  query FetchDynamicText {
    FetchDynamicText {
      code
      success
      message
      about
      aboutImageOrVideo
      contactUs
      orderNoteLabel
    }
  }
`;

export const UPDATE_CONTENT = gql`
  mutation UpdateDynamicText($about: String, $contactUs: String, $orderNoteLabel: String, $aboutImageOrVideo: String) {
    UpdateDynamicText(about: $about, contactUs: $contactUs, orderNoteLabel:$orderNoteLabel, aboutImageOrVideo: $aboutImageOrVideo) {
      code
      success
      message
    }
  }
`;

export const SENT_NEWSLETTER = gql`
  mutation SentNewsLatter(
    $productDetails: [NewsLatterInput]
    $subject: String
    $title: String
  ) {
    SentNewsLatter(
      productDetails: $productDetails
      subject: $subject
      title: $title
    ) {
      code
      success
      message
    }
  }
`;
