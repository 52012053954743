import { Button } from "antd";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import { getFile, warnNotify } from "../../util";
import {
  FILE_UPLOAD,
} from "../../graphql/modules";
import { Spin } from "antd";

import { useMutation } from "@apollo/react-hooks";

const AboutContent = ({ onTextHandler, contentText, saveContent, loading, aboutImageOrVideo, setAboutImageOrVideo }) => {
  const [FileUploadMutaion, { loading: uploadLoading }] =
  useMutation(FILE_UPLOAD);
const uploadFile = async (e) => {
  const file = e.target.files[0];
  try {
    const {
      data: { SingleUpload },
    } = await FileUploadMutaion({
      variables: {
        file,
      },
    });
    // console.log(SingleUpload)
    if (SingleUpload.success) {
      // let type = file.type.includes("video") ? "video" : "image";
      setAboutImageOrVideo(SingleUpload.filename);
      // setFileType(type);
      // uploadBanner(SingleUpload.filename, type);
    } else {
      warnNotify(SingleUpload.message);
    }
  } catch (error) {
    console.log(error.message);
  }
};
  return (
    <Fragment>
      <ReactQuill
        modules={AboutContent.modules}
        value={contentText || ""}
        onChange={onTextHandler}
        className="mb-4"
      />
      <div className="mb-4">
          <Spin spinning={uploadLoading} >
            <div className="mb-2">
              <img width="300px" src={getFile(aboutImageOrVideo)} alt="" />
            </div>
          </Spin>
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="file"
              accept="image/*"
              // accept="video/*,image/*"
              onChange={uploadFile}
              id="video-upload"
              disabled={uploadLoading}
            />
          </div>
        </div>
      <div className="text-right">
        <Button
          type="primary"
          disabled={!contentText || loading}
          onClick={saveContent}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </Fragment>
  );
};

export default AboutContent;

AboutContent.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote", "link"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
